import { Slide } from '@/types/slides'

export const slides: Slide[] = [
  {
    id: 'test-slide-1',
    elements: [
      {
        type: 'shape',
        id: '4cbRxp',
        left: 0,
        top: 200,
        width: 546,
        height: 362.5,
        viewBox: [200, 200],
        path: 'M 0 0 L 0 200 L 200 200 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        opacity: 0.7,
        rotate: 0
      },
      {
        type: 'shape',
        id: 'ookHrf',
        left: 0,
        top: 0,
        width: 300,
        height: 320,
        viewBox: [200, 200],
        path: 'M 0 0 L 0 200 L 200 200 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        flipV: true,
        rotate: 0
      },
      {
        type: 'text',
        id: 'idn7Mx',
        left: 355,
        top: 65.25,
        width: 585,
        height: 188,
        lineHeight: 1.2,
        content: '<p><strong><span style=\'font-size:  112px\'>PPTIST</span></strong></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333'
      },
      {
        type: 'text',
        id: '7stmVP',
        left: 355,
        top: 253.25,
        width: 585,
        height: 56,
        content: '<p><span style=\'font-size:  24px\'>基于 Vue 3.x + TypeScript 的在线演示文稿应用</span></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333'
      },
      {
        type: 'line',
        id: 'FnpZs4',
        left: 361,
        top: 238,
        start: [0, 0],
        end: [549, 0],
        points: ['', ''],
        color: '#5b9bd5',
        style: 'solid',
        width: 2,
      },
    ],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  },
  {
    id: 'test-slide-2',
    elements: [
      {
        type: 'text',
        id: 'ptNnUJ',
        left: 145,
        top: 148,
        width: 711,
        height: 77,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333',
      }, 
      {
        type: 'text',
        id: 'mRHvQN',
        left: 207.50000000000003,
        top: 249.84259259259264,
        width: 585,
        height: 56,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处添加副标题</span></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333',
      }, 
      {
        type: 'line',
        id: '7CQDwc',
        left: 323.09259259259267,
        top: 238.33333333333334,
        start: [0, 0],
        end: [354.8148148148148, 0],
        points: ['', ''],
        color: '#5b9bd5',
        style: 'solid',
        width: 4
      }, 
      {
        type: 'shape',
        id: '09wqWw',
        left: -27.648148148148138,
        top: 432.73148148148147,
        width: 1056.2962962962963,
        height: 162.96296296296296,
        viewBox: [200, 200],
        path: 'M 0 20 C 40 -40 60 60 100 20 C 140 -40 160 60 200 20 L 200 180 C 140 240 160 140 100 180 C 40 240 60 140 0 180 L 0 20 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        rotate: 0
      }
    ],
    background: {
      type: 'solid',
      color: '#fff',
    },
  },
  {
    id: 'test-slide-3',
    elements: [
      {
        type: 'shape',
        id: 'vSheCJ',
        left: 183.5185185185185,
        top: 175.5092592592593,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        rotate: 0
      }, 
      {
        type: 'shape',
        id: 'Mpwv7x',
        left: 211.29629629629628,
        top: 201.80555555555557,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        rotate: 0,
        opacity: 0.7
      }, 
      {
        type: 'text',
        id: 'WQOTAp',
        left: 304.9074074074074,
        top: 198.10185185185182,
        width: 417.9629629629629,
        height: 140,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 80px\'>感谢观看</span></span></strong></p>',
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333',
        wordSpace: 5
      }
    ],
    background: {
      type: 'solid',
      color: '#fff',
    },
  },
]