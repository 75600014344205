<template>
  <router-view></router-view>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>