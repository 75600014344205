import { createRouter, createMemoryHistory } from 'vue-router'
import Home from '@/views/Home/index.vue'
import Dashboard from '@/views/Dashboard/index.vue'
import Workbench from '@/views/Workbench/index.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/Workbench', // 动态参数
        name: 'Workbench',
        component: Workbench
    }
]
export const router = createRouter({
    history: createMemoryHistory(),
    routes: routes
})